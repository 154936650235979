.landing__btn {
  color: white;
  transition: 0.5s;
}
.loopanim {
  width: 100%;
  height: auto;
  position: relative;
}
.about-us {
  position: relative;
}
.landing-video {
  height: auto;
  width: 100%;
}
.discord-link {
  color: #cf152d !important;
}
@media (max-width: 1024px) {
}
