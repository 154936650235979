/* .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: 45px !important;
} */
.css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {
  display: none !important;
}
.faq__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #000;
  padding: 64px 0 128px 0;
  position: relative;
  bottom: 5px;
}
.MuiButtonBase-root {
  border-radius: 10px !important;
  padding-left: 16px !important;
}
.MuiAccordion-root:hover {
  background-color: #cf152d !important;
}

.MuiAccordion-root {
  margin-top: 20px !important;
}
.faq__body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  border: none !important;
  margin: 200px 0 88px 0;
}
.title2 {
  font-family: Stranger;
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  color: #cf152d;
}
.title {
  font-size: 16px;
  color: black;
  font-family: pilat;
  line-height: 150%;
  /* identical to box height */
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.08em;

  color: #000000;
}
.MuiPaper-root {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;

  background-color: transparent;
  border-radius: 10px !important;
}
.faq {
  color: #000;

  border: none;
}
.cont2 {
  background-color: transparent !important;
  padding: 0vh;
  color: #000;
}
.cont {
  padding: 0vh;
  /* background-color: #000 !important; */
  color: #000;
}
.text1 {
  font-size: 1rem !important;
  color: #000;
  padding: 0vh;
  text-align: left;
  line-height: 170%;
  /* or 30px */
  text-align: left !important;
  letter-spacing: 0.06em;
  font-family: "Poppins", sans-serif !important;
  color: #000000 !important;
}
.text2 {
  padding: 0vh !important;
  font-size: 1rem !important;
  color: black !important;
  font-weight: normal !important;
  font-family: "Poppins", sans-serif !important;
  line-height: 170%;
  /* or 30px */
  text-align: left !important;
  letter-spacing: 0.06em;
  color: #000000;
}
.MuiAccordionSummary-expandIconWrapper {
  margin-right: 8px;
}
.faq__ans {
  font-family: "Poppins", sans-serif !important;
  font-size: 0.85rem !important;

  font-weight: normal !important;
  text-align: left !important;
  padding: 0vh;
  line-height: 170%;
  /* or 30px */

  letter-spacing: 0.06em;

  color: #000000 !important;
}
.grid {
  width: 850px !important;
}

.MuiAccordionDetails-root {
}
.MuiAccordionDetails-root {
  border-bottom: none;
}
@media screen and (max-width: 1024px) {
  .faq__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000;
    padding: 0px 0 128px 0;
    position: relative;
    bottom: 5px;
  }
  .grid {
    width: 90vw !important;
  }
  .cont2 {
    /* padding: 0.5vh; */
  }
  .cont {
    /* padding: 0.5vh; */
  }
  .text1 {
    font-size: 0.85rem;
    line-height: 150%;
    /* or 30px */
    letter-spacing: 0.06em;

    padding: 0vh;
    text-align: left !important;
    font-weight: normal;
  }
  .text2 {
    padding: 0vh;
    font-size: 0.85rem;
    line-height: 150%;
    /* or 30px */
    letter-spacing: 0.06em;

    font-weight: normal;
    text-align: left !important;
  }
  .faq__ans {
    font-weight: normal;
    font-size: 0.85rem;
    line-height: 150%;
    /* or 30px */
    letter-spacing: 0.06em;
    font-weight: normal !important;
    text-align: left !important;
  }
  .title {
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.08em;

    color: #000000;
    font-weight: 600;
  }
}
@media screen and (width: 1024px) {
  .grid {
    width: 90vw !important;
  }
  .title {
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.08em;
    font-weight: 600;
  }

  .text1 {
    font-size: 0.85rem;
    line-height: 150%;
    /* or 30px */

    letter-spacing: 0.06em;
    font-weight: normal;

    text-align: left !important;
  }
  .text2 {
    font-size: 0.85rem;
    line-height: 150%;
    /* or 30px */

    letter-spacing: 0.06em;

    font-weight: normal;
    text-align: left !important;
  }
  .faq__ans {
    font-weight: normal;
    font-size: 0.85rem;
    line-height: 150%;
    /* or 30px */

    letter-spacing: 0.06em;

    font-weight: normal !important;
    text-align: left !important;
  }
}
@media screen and (max-width: 912px) {
  .grid {
    width: 90vw !important;
  }
}
