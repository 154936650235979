/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap'); */

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

body {
  margin: 0;
  /* font-family: Poppins; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

body::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #02080d; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #9000ff; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* unvisited link */
a:link {
  color: #9000ff;
}

/* visited link */
a:visited {
  color: #c1b4d6;
}

/* mouse over link */
a:hover {
  color: rgb(255, 255, 255);
}

/* selected link */
a:active {
  color: #9000ff;
}
